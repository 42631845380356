import { Paper, Typography, List, ListItemText, ListItemButton, Divider, ListItemIcon, ListItem } from '@mui/material';
import { useRouteLoaderData, useNavigate } from 'react-router-dom';
import { RouteIds } from '../Constants';
import { AuthData } from '../services/authService';
import { useEffect, useState } from 'react';
import { ICircle } from 'models/Circle';
import { Paths } from 'models/Paths';
import { getCurrentDate, getRelativeDate, secureFetch } from '../Util';
import { Add, Circle } from '@mui/icons-material';

export const Home = () => {
  const authData = useRouteLoaderData(RouteIds.Root) as AuthData;
  const [circles, setCircles] = useState<ICircle[]>([]);
  const [loadingCircles, setLoadingCircles] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    secureFetch(`/api${Paths.Circle.Base}${Paths.Circle.Get}`, 'POST', {
      circles: [authData.user.circle, ...authData.user.circles],
    }).then((res) => {
      if (res.ok) {
        res.json().then((circles) => {
          setCircles(circles);
          setLoadingCircles(false);
        });
      }
    });
  }, [authData]);

  const createCircle = () => {
    secureFetch(`/api${Paths.Circle.Base}${Paths.Circle.Create}`, 'POST', {
      name: authData.user.name,
      email: authData.user.id ?? '',
    }).then((res) => {
      if (res.ok) {
      }
    });
  };

  const goToCircle = (circle: ICircle) => {
    // get latest entry date
    secureFetch(`/api${Paths.Journal.Base}${Paths.Journal.LatestEntry}`, 'POST', {
      circle: circle.id,
    }).then(async (res) => {
      if (res.ok) {
        const latestEntry: string = await res.json();
        navigate(`/circle/${circle.id}/${getRelativeDate(latestEntry, circle.timezone, 1).toISODate() ?? getCurrentDate(circle.timezone)}`);
      }
    });
  };

  return (
    <Paper style={{ margin: '2em 5em', padding: '1em', display: 'flex', gap: '1em', flexDirection: 'column' }}>
      <Typography style={{ textAlign: 'center' }} variant="h3">
        Welcome to Circles {authData.user.name}!
      </Typography>
      <Paper elevation={2}>
        <List>
          {loadingCircles ? (
            <ListItem>
              <ListItemText>Loading circles...</ListItemText>
            </ListItem>
          ) : (
            circles.map((circle) => (
              <ListItemButton key={circle.id} onClick={() => goToCircle(circle)}>
                <ListItemIcon>
                  <Circle />
                </ListItemIcon>
                <ListItemText primary={circle.name} />
              </ListItemButton>
            ))
          )}
          <Divider />
          <ListItemButton onClick={createCircle}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Create Circle" />
          </ListItemButton>
        </List>
      </Paper>
    </Paper>
  );
};
