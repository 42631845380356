import React, { useState } from 'react';
import { useAuth } from '../contexts/Auth';
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import logo from '../logo.svg';
import styles from './Auth.module.css';

export const Register = () => {
  const [loading, isLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const auth = useAuth();

  const register = async () => {
    isLoading(true);
    setLoginError(await auth.register(email, username));
    isLoading(false);
  };

  return (
    <Stack className={styles.container}>
      <img src={logo} alt="logo" className={styles.logo} />
      <Typography variant="h3">Sign up for a new Circle.</Typography>
      <Stack className={styles.formStack}>
        <TextField label="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField label="Username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} helperText={loginError} />
      </Stack>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button size="large" onClick={register} variant="contained" color="primary" className={styles.registerButton}>
          Register
        </Button>
      )}
    </Stack>
  );
};
