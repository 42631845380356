import React, { useMemo, useState } from 'react';
import { useAuth } from '../contexts/Auth';
import { secureFetch } from '../Util';
import { Paths } from 'models/Paths';
import { AppBar, Button, CircularProgress, IconButton, Menu, MenuItem, Toolbar, Typography, useColorScheme } from '@mui/material';
import { Outlet, useNavigate, useNavigation, useRouteLoaderData } from 'react-router-dom';
import { AuthData } from '../services/authService';
import { ExpandMore, Logout, Settings } from '@mui/icons-material';
import logo from '../logo.svg';
import { RouteIds } from '../Constants';
import styles from './Root.module.css'; // Import the CSS module

export const Root = () => {
  const authData = useRouteLoaderData(RouteIds.Root) as AuthData;
  const auth = useAuth();

  const navigate = useNavigate();
  const navigation = useNavigation();
  const user = useMemo(() => authData.user, [authData]);

  const { mode, setMode } = useColorScheme();
  const [inviteMenuAnchorEl, setInviteMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    setAnchor: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  ) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor: React.Dispatch<React.SetStateAction<null | HTMLElement>>) => {
    setAnchor(null);
  };

  const acceptInvite = (circle: string) => {
    secureFetch(`/api${Paths.Circle.Base}${Paths.Circle.AcceptInvite}`, 'POST', {
      circle: circle,
      email: user?.id ?? '',
    }).then((res) => {
      if (res.ok) {
        auth.refreshUser();
      }
    });
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <div className={styles.flexGrow}>
            <Button onClick={() => navigate('/')} className={styles.buttonStyle} color="secondary">
              <img src={logo} alt="logo" className={styles.logoStyle} />
              <Typography variant="h6">Circles</Typography>
            </Button>
          </div>
          <Button color="inherit" onClick={(event) => handleMenuOpen(event, setInviteMenuAnchorEl)}>
            <Typography>Pending invites</Typography>
            <ExpandMore />
          </Button>
          <Menu anchorEl={inviteMenuAnchorEl} open={Boolean(inviteMenuAnchorEl)} onClose={() => handleMenuClose(setInviteMenuAnchorEl)}>
            {user?.invites.map((invite) => (
              <MenuItem key={invite.circle} onClick={() => acceptInvite(invite.circle)}>
                {invite.circle}
              </MenuItem>
            ))}
            {user.invites.length === 0 && <MenuItem>No pending invites!</MenuItem>}
          </Menu>
          {user?.role === 'admin' && (
            <Button color="inherit" onClick={() => navigate('/AdminDashboard')}>
              Admin Dashboard
            </Button>
          )}
          <IconButton color="inherit" onClick={(e) => handleMenuOpen(e, setSettingsAnchorEl)}>
            <Settings />
          </IconButton>
          <Menu anchorEl={settingsAnchorEl} open={Boolean(settingsAnchorEl)} onClose={() => handleMenuClose(setSettingsAnchorEl)}>
            <MenuItem selected={mode === 'system'} onClick={() => setMode('system')}>
              System Theme
            </MenuItem>
            <MenuItem selected={mode === 'light'} onClick={() => setMode('light')}>
              Light Theme
            </MenuItem>
            <MenuItem selected={mode === 'dark'} onClick={() => setMode('dark')}>
              Dark Theme
            </MenuItem>
          </Menu>
          <Button
            color="inherit"
            onClick={async () => {
              await auth.signOut();
              navigate('/signin');
            }}
          >
            <Logout />
          </Button>
        </Toolbar>
      </AppBar>
      {navigation.state === 'loading' ? <CircularProgress /> : <Outlet />}
    </>
  );
};
